
import { Options, Vue } from "vue-class-component";
import { FlightDetail } from "@/domain/entities/NGen";
import ScheduleCard from "@/app/ui/views/cargo/booking-cargo/components/plane-schedule/schedule-card.vue";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import CargoTypeCard from "@/app/ui/views/cargo/booking-cargo/components/cargo-type-card.vue";
import PlaneSchedule from "@/app/ui/views/cargo/booking-cargo/components/plane-schedule/index.vue";
import { CargoConfigurationController } from "@/app/ui/controllers/CargoConfigurationController";
import { CargoRecomendationsListRequest } from "@/data/payload/api/CargoApiRequest";
import {
  CargoConfiguration,
  CargoListRecomendations,
  SchedulePlane
} from "@/domain/entities/CargoConfiguration";
import { ModalMessageEntities, OptionsClass } from "@/domain/entities/MainApp";
import { ApiStateData } from "@/domain/entities/Api";
import ListCargoType from "./list-cargo-type.vue";
import { getTokenNGen } from "@/app/infrastructures/misc/Cookies";
import { cargoTypeData } from "../../modules/cargo-module";
import { gtmCargoData } from "../../modules/gtm";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { NGenController } from "@/app/ui/controllers/NGenController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import firstCapitalize from "@/app/infrastructures/misc/common-library/FirstCapitalize";
import ConfigFeatureFlight from "@/app/ui/views/cargo/booking-cargo/config-feature-flight/config-feature-flight.vue";
import { FlagsPermissionCargo } from "@/feature-flags/flags-permission-cargo";

@Options({
  components: {
    ScheduleCard,
    CargoTypeCard,
    PlaneSchedule,
    ListCargoType,
    ConfigFeatureFlight
  }
})
export default class SecondStep extends Vue {
  async mounted() {
    if (!this.flagFeatureFlight) MainAppController.showLoading();
    await this.onGetListCargoRecomendation();
  }
  beforeUnmount() {
    this.showPlaneSchedule = false;
    this.cargoRecomendationsData = [];
  }

  get form() {
    return CargoController.form;
  }

  get totalStt(): number {
    return CargoController.sttResultFinal.length;
  }

  // tabs
  activeMenu = "recomendation";
  onChangeHistoryTabs(value: string) {
    this.activeMenu = value;
    if (value === "type") {
      this.searchCargo();
    }
  }
  get tabs() {
    return [
      {
        value: "recomendation",
        title: "Rekomendasi Kargo"
      },
      {
        value: "type",
        title: "Pilihan Kargo"
      }
    ];
  }

  get isLoading(): boolean {
    return MainAppController.loadingModal;
  }

  cargoRecomendationsData: CargoListRecomendations[] = [];
  apiStateData: ApiStateData = new ApiStateData();
  isPlaneAutoRecommendation = false;
  async onGetListCargoRecomendation() {
    try {
      if (!this.flagFeatureFlight) MainAppController.showLoading();
      this.apiStateData = new ApiStateData({
        loading: true,
        errorType: ""
      });

      const data: CargoConfiguration[] = await CargoConfigurationController.getListCargoRecomendation(
        new CargoRecomendationsListRequest({
          originCode: this.originCity.code,
          destinationCode: CargoController.destinationCity?.code,
          flightDay: this.form.estDepartureDate
            .toLocaleDateString("en-EN", { weekday: "long" })
            .toLowerCase(),
          comodity: this.form.cargoCommodityCode,
          totalWeight: this.form.actualTotalCargoGrossWeight.replace(",", "."),
          totalVolume: this.form.actualTotalCargoVolumeWeight.replace(",", ".")
        })
      );

      if (data.length) {
        this.onHaveData(data);
      } else {
        this.searchCargo();
      }
    } catch (error) {
      this.cargoRecomendationsData = [];
      this.searchCargo();
    } finally {
      this.apiStateData = new ApiStateData({
        loading: false
      });
      MainAppController.closeLoading();
    }
  }
  onHaveData(data: CargoConfiguration[]) {
    for (const res of data) {
      if ((res.transportationType as OptionsClass).value.match(/plane/)) {
        for (const [index, item] of res.schedulePlaneForm.entries()) {
          const startTime = new Date().setHours(
            new Date(item.startTime).getHours(),
            new Date(item.startTime).getMinutes()
          );
          const endTime = new Date().setHours(
            new Date(item.endTime).getHours(),
            new Date(item.endTime).getMinutes()
          );
          if (
            new Date() >= new Date(startTime) &&
            new Date() <= new Date(endTime)
          ) {
            this.onValidDate(res, item, index);
          }
        }
        if (
          !this.cargoRecomendationsData.find(
            (item: CargoListRecomendations) =>
              (item.transportationType as OptionsClass).value === "plane"
          )
        ) {
          this.isPlaneAutoRecommendation = true;
          this.searchCargo();
        }
      } else {
        this.cargoRecomendationsData.push(
          new CargoListRecomendations({
            transportationType: res.transportationType,
            flightData: new FlightDetail()
          })
        );
      }
    }
  }
  onValidDate(res: any, item: any, index: number) {
    if (item.transit.length) {
      this.cargoRecomendationsData.push(
        new CargoListRecomendations({
          transportationType: res.transportationType,
          flightData: new FlightDetail({
            DailyFlightSNo: index,
            FlightNo: item.flightNo,
            DepartureDateTime: item.departureDate,
            ArrivalDateTime: item.transit[item.transit.length - 1].arrivalDate,
            Route: `${this.originCity.code}-${this.destinationCity?.code}`,
            transitsData: [
              new FlightDetail({
                DailyFlightSNo: index,
                FlightNo: item.flightNo,
                DepartureDateTime: item.departureDate,
                ArrivalDateTime: item.arrivalDate
              }),
              ...item.transit.map(
                (transit: SchedulePlane) =>
                  new FlightDetail({
                    DailyFlightSNo: index,
                    FlightNo: transit.flightNo,
                    DepartureDateTime: transit.departureDate,
                    ArrivalDateTime: transit.arrivalDate
                  })
              )
            ]
          })
        })
      );
    } else {
      this.cargoRecomendationsData.push(
        new CargoListRecomendations({
          transportationType: res.transportationType,
          flightData: new FlightDetail({
            DailyFlightSNo: index,
            FlightNo: item.flightNo,
            DepartureDateTime: item.departureDate,
            ArrivalDateTime: item.arrivalDate,
            Route: `${this.originCity.code}-${this.destinationCity?.code}`
          })
        })
      );
    }
  }
  cargoType(type: string) {
    return cargoTypeData(type);
  }

  // search cargo
  searchCargo() {
    CargoController.getCargoOptions({
      origin: {
        code: this.originCity.code || "",
        name: this.originCity.name
      },
      destination: {
        code: this.destinationCity?.code || "",
        name: this.destinationCity?.name
      },
      isPlaneManual: true
    });
  }
  get cargoOptions() {
    return CargoController.cargoOptions.data.map(item => item.transportType);
  }

  get totalSttPieces() {
    return CargoController.totalSttPieces;
  }
  get flagFeatureFlight() {
    return FlagsPermissionCargo.feature_flight_v2_enable.isEnabled();
  }

  validationMaxPieces(cargoType: string) {
    const validation = (
      cargoType === "plane" &&
      this.totalSttPieces > 15 &&
      !this.flagFeatureFlight ||
        cargoType === "plane" &&
          this.totalSttPieces > 15 &&
          this.form.cargoTypeStatus === "manual"
    );

    return validation
  }

  showPopupMaxKoliPlane(data: CargoListRecomendations) {
    const cargoType = (data.transportationType as OptionsClass).value;
    if (this.validationMaxPieces(cargoType)) {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: this.$t("Maksimal Koli Pesawat adalah 15 koli"),
          image: "max-koli-plane",
          message:
            this.$t("Mohon ubah jumlah koli di dalam kargo atau pilih transportasi kargo lainnya"),
          textCancel: this.$t("Transportasi Lain"),
          textSuccess: this.$t("Ubah Koli"),
          onClose: () => {
            MainAppController.closeMessageModal();
          },
          onSubmit: () => {
            MainAppController.closeMessageModal();
            CargoController.setProgressValue(0);
          }
        })
      );
      return;
    }
    this.onSelectCargoRecomendation(data);
  }

  onSelectCargoRecomendation(data: CargoListRecomendations) {
    const cargoType = (data.transportationType as OptionsClass).value;
    this.form.cargoType = cargoType;

    if (cargoType === "plane") {
      this.form.flightsData = data.flightData.transitsData.length
        ? data.flightData.transitsData
        : [data.flightData];
      this.form.cargoTypeStatus = "auto";
      if (data.flightData.transitsData.length) {
        const transits: Array<string> = Array(
          data.flightData.transitsData.length - 1
        ).fill("");
        this.form.airports.splice(1, 0, ...transits);
      }
    } else {
      this.form.cargoTypeStatus = "manual";
    }
    CargoController.setProgressValue(2);
  }

  // origin city
  get originCity(): OptionsClass {
    const detailDistrict = LocationController.districtDetail;
    return new OptionsClass({
      name: `${detailDistrict.cityCode} - ${firstCapitalize(
        detailDistrict.cityName.toLowerCase()
      )}`,
      code: detailDistrict.cityCode
    });
  }
  get destinationCity(): OptionsClass {
    return CargoController.destinationCity;
  }

  get showPlaneSchedule() {
    return CargoController.isShowPlaneSchedule;
  }
  set showPlaneSchedule(value: boolean) {
    CargoController.setShowPlaneSchedule(value);
  }

  // cargo flight auto
  get isNGenValid() {
    return !!getTokenNGen();
  }
  setDefaultDataToAirportPopup() {
    CargoController.setAirportsPopupModel(
      JSON.parse(JSON.stringify(this.form.airports))
    );
  }
  get showSelectAirport() {
    return CargoController.showSelectAirport;
  }
  set showSelectAirport(bool: boolean) {
    CargoController.setShowSelectAirport(bool);
  }
  cancelPopupAirport() {
    this.setDefaultDataToAirportPopup();
    this.showSelectAirport = false;
  }
  async onResetPopup() {
    this.showSelectAirport = this.isNGenValid;
    if (!this.showPlaneSchedule) CargoController.resetModalPlaneAuto();
  }
  onResetChangeTransitAirport() {
    this.form.isChangeAirport = true;
    this.cancelPopupAirport();
    this.onResetPopup();
  }

  onShowBookingForm(val: boolean) {
    CargoController.setShowBookingForm(val);
  }
  async onBookingCargo(type: string, status: string) {
    // cargo plane auth
    if (type === "plane") {
      await NGenController.onLoginNGen();
    }

    this.form.cargoType = type;
    this.form.cargoTypeStatus = status;

    // cargo plane auto
    if (type === "plane" && status === "auto") {
      const gtmPlaneAuto = gtmCargoData({
        type: this.form.cargoType,
        isAuto: true
      }).type;

      // gtm cargo auto
      dataLayer(gtmPlaneAuto.eventName, gtmPlaneAuto.property, []);
      this.cancelPopupAirport();
      this.onResetPopup();
      this.showSelectAirport = this.isNGenValid;
    } else {
      // gtm cargo manual
      if (type !== "ship") {
        const gtmCargoType = gtmCargoData({ type: this.form.cargoType })?.type;
        dataLayer(gtmCargoType.eventName, gtmCargoType.property, []);
      }

      // go to form booking
      this.onShowBookingForm(type === "plane" ? this.isNGenValid : true);

      // gtm truck/train
      if (this.form.cargoType === "truck" || this.form.cargoType === "train") {
        const gtmDetailView = gtmCargoData({ type: this.form.cargoType })
          .detailView;

        dataLayer(gtmDetailView.eventName, gtmDetailView.property, []);
      }
      CargoController.setProgressValue(2);
    }
  }

  // auto recommendation
  showPopupMaxKoliPlaneAuto(type: string, status: string) {
    if (this.validationMaxPieces(type)) {
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: this.$t("Maksimal Koli Pesawat adalah 15 koli"),
          image: "max-koli-plane",
          message:
            this.$t("Mohon ubah jumlah koli di dalam kargo atau pilih transportasi kargo lainnya"),
          textCancel: this.$t("Transportasi Lain"),
          textSuccess: this.$t("Ubah Koli"),
          onClose: () => {
            MainAppController.closeMessageModal();
          },
          onSubmit: () => {
            MainAppController.closeMessageModal();
            CargoController.setProgressValue(0);
          }
        })
      );
      return;
    }
    this.onBookingCargo(type, status);
  }
}
